import { Injectable } from '@angular/core';
import { Servicio } from '../../models/negocio/servicio.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConstants } from '../../app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
    providedIn: 'root'
})
export class ServicioService {
    _baseUrl: string;

    constructor(private http: HttpClient, private loginService: LoginService) {
        this._baseUrl = AppConstants.baseURL;
    }
    getAllServicios() {
        const headers = new HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
        return this.http.get(this._baseUrl + '/api/Servicio', { headers });
    }
    crearServicio(req) {
        const headers = new HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
        return this.http.post(this._baseUrl + '/api/Servicio', {
            "servicio": req.servicio,
            "urlLogo": req.urlLogo
        }, { headers });
    }
    getByIdServicio(id) {
        const headers = new HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
        return this.http.get<Servicio>(this._baseUrl + '/api/Servicio/' + id, { headers });
    }
    updateServicio(req) {
        const headers = new HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
        return this.http.put(this._baseUrl + '/api/Servicio', {
            "_id": req._id,
            "id": req.id,
            "servicio": req.servicio,
            "urlLogo": req.urlLogo,
            "estado": req.estado
        }, { headers });
    }
    getListServicioVariable(pagina, numeroRegistros) {
        const headers = new HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
        return this.http.post(this._baseUrl + '/api/servicio/PostObtenerTodos', {
            "estado": "Activo",
            "pagina": pagina,
            "numeroRegistros": numeroRegistros
        }, { headers });
    }
    updateEstadoServicio(idservicio, idmandante, estado) {
        const headers = new HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
        return this.http.put(this._baseUrl + '/api/Servicio/PutCambiaEstadoServicio', {
            "idServicio": idservicio,
            "idMandante": idmandante,
            "estado": estado
        }, { headers });
    }
}
