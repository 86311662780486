import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppConstants } from '../../app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class PaisService {

  _baseUrl: string;
  constructor(private http : HttpClient, private loginService : LoginService) {
    this._baseUrl = AppConstants.baseURL;
  }

  getAllPaises(pagina, numRegistro){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/divisionPolitica/getAllPaises',{
      "pagina": pagina,
      "numeroRegistros" : numRegistro,
    }, {headers}).pipe(map((resp: any) => {
      return resp;
    }));
  }

  setPais(uid, id, nombrePais, codigoPais, codigoTelefono, labelRegion, labelComuna, labelVerificador, estadoPais, algoritmoVerificador) {
    const headers = new HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.put(this._baseUrl + '/api/divisionPolitica/putActualizaPais', {
      "uid": uid,
      "id": id,
      "nombrePais": nombrePais,
      "codigoPais": codigoPais,
      "codigoTelefono": codigoTelefono,
      "labelRegion": labelRegion,
      "labelComuna": labelComuna,
      "labelVerificador": labelVerificador,
      "estado": estadoPais,
      "algoritmoVerificador": algoritmoVerificador
    }, {headers}).pipe(map((resp: any) => {
      return resp;
    }));
  }
}
