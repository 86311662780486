import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import {
  NominaService,
  LoginService,
  MandanteService,
  PlanService,
  ServicioService,
  UsuarioService,
  ValorservicioService,
  VariableService,
  ContratoService,
  FactibilidadService,
  MensajeService,
  OportunidadService,
  RegionService,
  SubirArchivoService,
  VerficaTokenGuard,
  RoleGuardGuard,
  DescargarArchivoService,
  AsignarService,
  AuditoriaService
} from "./services.index";

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    NominaService,
    LoginService,
    MandanteService,
    PlanService,
    ServicioService,
    UsuarioService,
    ValorservicioService,
    VariableService,
    ContratoService,
    FactibilidadService,
    MensajeService,
    OportunidadService,
    RegionService,
    SubirArchivoService,
    VerficaTokenGuard,
    RoleGuardGuard,
    DescargarArchivoService,
    AsignarService,
    AuditoriaService
  ],
  declarations: []
})
export class ServicesModule {}
