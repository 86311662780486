import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Variable } from '../../models/negocio/variable.model';
import { map } from 'rxjs/operators';


import { AppConstants } from '../../app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class VariableService {
  _baseUrl: string;
  token : string;
  constructor(private http : HttpClient, private loginService : LoginService) { 
    this._baseUrl = AppConstants.baseURL;
    this.token = loginService.token;
  }

  crearVariable(variable){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/Variable',{
      'nombreVariable': variable.nombreVariable,
      'idServicio' : variable.idServicio
    },{headers});
  }
  getAllVariables(){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/Variable',{headers});
  }
  updateVariables(variable, idServicio){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.put(this._baseUrl + '/api/variable/PutVariables',{
      // "_id": variable._id,
      // "id": variable.id,
      // "nombreVariable": variable.nombreVariable,
      // "idServicio" : variable.idServicio,
      // 'estado': variable.estado
      "idServicio" : idServicio,
      "listaVariable" : variable
    },{headers});
  }
  getVariablesByIdServicio(id){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/Variable/GetVariablesByIdServicio/'+ id,{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }

  agregarVariableValor(idMandante, idServicio, mandanteServicioVariableValor){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.put(this._baseUrl + '/api/variable/PutVariableValor',{
      "id": mandanteServicioVariableValor.id,
      "_id": mandanteServicioVariableValor._id,
      "idMandante": mandanteServicioVariableValor.idMandante,
      "idServicio": mandanteServicioVariableValor.idServicio,
      "listaVariableValor" : mandanteServicioVariableValor.listaVariableValor
    },{headers});
  }

  getVariableValor(idServicio, idMandante){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/variable/getVariableServicioValor/'+ idServicio+'/'+idMandante,{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }
}
