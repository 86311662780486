import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpHeaders
} from "@angular/common/http";
import { AppConstants } from "../../app.constansts";
import { LoginService } from "../auth/login/login.service";
import swal from "src/assets/styles/vendors/sweetalert2/dist/sweetalert2.js";
import "src/assets/styles/vendors/sweetalert2/src/sweetalert2.scss";

@Injectable({
  providedIn: "root"
})
export class NominaService {
  _baseUrl: string;
  progress: number;

  constructor(private http: HttpClient, private loginService: LoginService) {
    this._baseUrl = AppConstants.baseURL;
  }

//#region Generando Nómina de Pago

generarNomina(nomina) {
  return this.http
    .post(
      this._baseUrl + "/api/nomina/PostGenerarNomina",
      {
        fechaDesde: nomina.fechaDesde,
        fechaHasta: nomina.fechaHasta
      },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.loginService.token
        }),
        responseType: "blob"
      }
    )
    .subscribe(
      resp => {
        return this.downLoadFile(
          resp,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      (error: HttpErrorResponse) => {
        console.log(error["control"], 'error["control"]');
        console.log(error, 'error["control"]');
        if (error.status == 404) {
          swal(
            "Error",
            "No existen registros para generar la nómina de pagos.",
            "error"
          );
        } else if (error.status == 400) {
          swal(
            "Error",
            "Ha ocurrido un error, favor intente más tarde.",
            "error"
          );
        }
      }
    );
}

generarNomina2(fechaInstalacion) {
  return this.http
    .post(
      this._baseUrl + "/api/nomina/postGenerarNomina2",
      {
        fechaInstalacion: fechaInstalacion
      },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.loginService.token
        }),
        responseType: "blob"
      }
    )
    .subscribe(
      resp => {
        return this.downLoadFile(
          resp,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      (error: HttpErrorResponse) => {
        console.log(error["control"], 'error["control"]');
        console.log(error, 'error["control"]');
        if (error.status == 404) {
          swal(
            "Error",
            "No existen registros para generar la nómina de pagos.",
            "error"
          );
        } else if (error.status == 400) {
          swal(
            "Error",
            "Ha ocurrido un error, favor intente más tarde.",
            "error"
          );
        }
      }
    );
}

generarNomina2OfficeBanking(fechaInstalacion) {
  return this.http
    .post(
      this._baseUrl + "/api/nomina/postGenerarOfficeBanking2",
      {
        fechaInstalacion: fechaInstalacion
      },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.loginService.token
        }),
        responseType: "blob"
      }
    )
    .subscribe(
      resp => {
        return this.downLoadFile(
          resp,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      (error: HttpErrorResponse) => {
        console.log(error["control"], 'error["control"]');
        console.log(error, 'error["control"]');
        if (error.status == 404) {
          swal(
            "Error",
            "No existen registros para generar la nómina de pagos.",
            "error"
          );
        } else if (error.status == 400) {
          swal(
            "Error",
            "Ha ocurrido un error, favor intente más tarde.",
            "error"
          );
        }
      }
    );
}

downLoadFile(data: any, type: string) {
  var blob = new Blob([data], { type: type });
  var url = window.URL.createObjectURL(blob);
  var pwa = window.open(url);
  if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
    alert("Please disable your Pop-up blocker and try again.");
  }
}

//#endregion

  //#region Subiendo Nómina de Pago

  subirEstadoPago_(file: File) {
    const formData = new FormData();
    formData.append(file.name, file);
    const headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + this.loginService.token
    );

    const uploadReq = new HttpRequest(
      "POST",
      this._baseUrl + "/api/nomina/postSubirEstadoPago",
      formData,
      {
        reportProgress: false,
        headers
      }
    );
    this.http.request(uploadReq).subscribe(
      resp => {
        if (resp.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * resp.loaded) / resp.total);
        }
        if (resp.type == HttpEventType.Response) {
          var uri = this._baseUrl + resp.body["data"];
          var pwa = window.open(uri);
          if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
            alert("Please disable your Pop-up blocker and try again.");
          }
        }
      },
      mesaje => {
        console.log(mesaje);
        if (mesaje.status == 400) {
          swal(
            "Error",
            mesaje.error["control"].mensajeControl.toString(),
            "error"
          );
        }
      }
    );
  }

  subirEstadoPago_OfficeBanking(file: File) {
    console.log("postSubirEstadoPagoOfficeBanking");
    const formData = new FormData();
    formData.append(file.name, file);
    const headers = new HttpHeaders().set(
      "Authorization",
      "Bearer " + this.loginService.token
    );

    const uploadReq = new HttpRequest(
      "POST",
      this._baseUrl + "/api/nomina/postSubirEstadoPagoOfficeBanking",
      formData,
      {
        reportProgress: false,
        headers
      }
    );
    this.http.request(uploadReq).subscribe(
      resp => {
        if (resp.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * resp.loaded) / resp.total);
        }
        if (resp.type == HttpEventType.Response) {
          var uri = this._baseUrl + resp.body["data"];
          var pwa = window.open(uri);
          if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
            alert("Por favor deshabilite bloqueo de Pop-up e intente nuevamente.");
          }
        }
      },
      mesaje => {
        console.log(mesaje);
        if (mesaje.status == 400) {
          swal(
            "Error",
            mesaje.error["control"].mensajeControl.toString(),
            "error"
          );
        }
      }
    );
  }

  //#endregion
}
