
import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages/pages.component';

import { LoginComponent } from './login/login.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { LoginGuardGuard } from './services/guards/login-guard.guard';
import { ErrorpermisosComponent } from './shared/errorpermisos/errorpermisos.component';

const appRutas: Routes = [
    { path: 'login', component: LoginComponent },
    {
        path:'',
        component: PagesComponent,
        canActivate: [LoginGuardGuard],
        loadChildren: './pages/pages.module#PagesModule'
    },
    { path: 'error', component: ErrorpermisosComponent },
    { path: '**', component: NopagefoundComponent }
];

export const APP_RUTAS = RouterModule.forRoot(appRutas, { scrollPositionRestoration: 'enabled',useHash: true });
