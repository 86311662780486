import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class VerficaTokenGuard implements CanActivate {
  constructor(public loginService : LoginService){

  }
  canActivate(): Promise<boolean> | boolean {
    let token = this.loginService.token;
    let payload = JSON.parse(atob(token.split('.')[1]));
    let expirado = this.expirado(payload.exp);
    if(expirado){
      this.loginService.logOut();
      return false;
    }

    return true;
  }
  expirado(fechaExp : number){
    let ahora = new Date().getTime() / 1000;
    if (fechaExp < ahora) {
      return true;
    } else {
      return false;
    }
  }
}
