import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardGuard implements CanActivate {
  constructor(private loginservice: LoginService, public router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot) {
    const ListaRoles = route.data.expectedRole;
    var expectedRole = ListaRoles.filter(rol => rol === this.loginservice.usuario.rol).toString();
    if (this.loginservice.usuario.rol === expectedRole) {
      return true;
    } else {
      this.router.navigate(['/error']);
      return false;
    }
  }
}
