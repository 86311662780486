import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpHeaders
} from "@angular/common/http";
import { AppConstants } from "../../app.constansts";
import { LoginService } from "../auth/login/login.service";
import swal from "src/assets/styles/vendors/sweetalert2/dist/sweetalert2.js";
import "src/assets/styles/vendors/sweetalert2/src/sweetalert2.scss";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  _baseUrl: string;
  progress: number;

  constructor(private http: HttpClient, private loginService: LoginService) {
    this._baseUrl = AppConstants.baseURL;
   }

   descargarOportunidades(periodo) {
    swal({ title: 'Descargando Oportunidades...', allowOutsideClick: false, showConfirmButton: false });
    swal.showLoading();
     return this.http.post(this._baseUrl + "/api/reportes/postObtieneOportunidades",
      {
        fechaDesde: periodo.fechaDesde,
        fechaHasta: periodo.fechaHasta,
        codigoInvitacion: periodo.codigo,
        rutAgente: periodo.rutAgente,
        autoCompra: periodo.autoCompra
      },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.loginService.token
        }),
        responseType: "blob"
      }
     )
     .subscribe(
      resp => {
        swal.close();
        return this.downLoadFile(
          resp,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      (error: HttpErrorResponse) => {
        console.log(error["control"], 'error["control"]');
        console.log(error, 'error["control"]');
        if (error.status == 404) {
          swal(
            "Error",
            "No existen oportunidades a descargar.",
            "error"
          );
        } else if (error.status == 400) {
          swal(
            "Error",
            "Ha ocurrido un error, favor intente más tarde.",
            "error"
          );
        }
      }
    );
   }

   getTotalOpEstados()
   {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl + '/api/reportes/getResumenReportes',{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
   }

   descargarAgentes() {
    swal({ title: 'Descargando Agentes...', allowOutsideClick: false, showConfirmButton: false });
    swal.showLoading();
     return this.http.post(this._baseUrl + "/api/reportes/postObtieneAgentes",
      {
      },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.loginService.token
        }),
        responseType: "blob"
      }
     )
     .subscribe(
      resp => {
        swal.close();
        return this.downLoadFile(
          resp,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      },
      (error: HttpErrorResponse) => {
        console.log(error["control"], 'error["control"]');
        console.log(error, 'error["control"]');
        if (error.status == 404) {
          swal(
            "Error",
            "No existen agentes a descargar.",
            "error"
          );
        } else if (error.status == 400) {
          swal(
            "Error",
            "Ha ocurrido un error, favor intente más tarde.",
            "error"
          );
        }
      }
    );
   }

   getTotalAgentes(){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl + '/api/reportes/getResumenAgentes',{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
   }

   downLoadFile(data: any, type: string) {
    var blob = new Blob([data], { type: type });
    var url = window.URL.createObjectURL(blob);
    var pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
      alert("Please disable your Pop-up blocker and try again.");
    }
  }

}
