import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injectable, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule , FormsModule } from '@angular/forms';
import { NgRutValidatorModule, RutValidator } from 'ng-rut-validator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//RUTAS
import { APP_RUTAS } from './app-routing.module';

//MODULOS
import { SharedModule } from './shared/shared.module';
import { UtilsModule } from './utils/utils.module';

// Servicios
import { ServicesModule } from './services/services.module';

//COMPONENTES
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PagesComponent } from './pages/pages.component';

// // Sentry
// import * as Sentry from "@sentry/browser";
// import { RewriteFrames } from '@sentry/integrations';

// Sentry.init({
//     dsn: 'https://be84c777bd7541388db5d0d609a2ce4d@o365488.ingest.sentry.io/5156430'
// });

//@Injectable()
// export class SentryErrorHandler implements ErrorHandler {
//     constructor() {}
//     handleError(error) {
//         const eventId = Sentry.captureException(error.originalError || error);
//         Sentry.showReportDialog({ eventId });
//     }
// }

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PagesComponent
  ],
  providers: [
      RutValidator
      // { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    APP_RUTAS,
    NgRutValidatorModule,
    ServicesModule,
    UtilsModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports:[],
  bootstrap: [AppComponent]
})
export class AppModule { }
