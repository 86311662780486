import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';


import { AppConstants } from 'src/app/app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class EjecutivoService {

  _baseUrl: string;
  token : string

  constructor(private http: HttpClient, private loginService : LoginService) {
    this._baseUrl = AppConstants.baseURL;
   }

   getAllEjecutivosConAsignaciones()
   {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl + '/api/ejecutivo/getAsignaciones',{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
   }
}
