import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

//componentes 
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { ErrorpermisosComponent } from './errorpermisos/errorpermisos.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    NopagefoundComponent,
    ErrorpermisosComponent
  ],
  exports: [
    NopagefoundComponent,
    ErrorpermisosComponent
  ]
})
export class SharedModule { }
