import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppConstants } from '../../app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  _baseUrl: string;
  token: string;

  constructor(private http: HttpClient, private loginService : LoginService) {
    this._baseUrl = AppConstants.baseURL;
    this.token = loginService.token;
  }
  getAllPlan(){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/Plan',{headers});
  }
  crearPlan(plan){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/Plan',{
      "idServicio": plan.idServicio,
      "idMandante": plan.idMandante,
      "plan": plan.plan,
      "descripcion": plan.descripcion,
      "imagenPlan": plan.imagenPlan,
      "valorComision": plan.valorComision
    },{headers});
  }
  getIdServicioByMandante(id){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/Servicio/getservicioplanes/'+id,{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }

  getPlanesByIdMandanteIdServicio(idMandante, idServicio){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl + '/api/Plan/postPlanesByMandanteServicioId/',{
      "idMandante": idMandante,
      "idServicio": idServicio
    },{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }

  getPlanById(idPlan){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.get(this._baseUrl + '/api/Plan/'+idPlan,{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }

  updatePlan(plan){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.token);
    return this.http.post(this._baseUrl +'/api/Plan/postActualizaPlan/',{
      "idServicio": plan.idServicio,
      "idMandante": plan.idMandante,
      "id": plan.id,
      "_id": plan._id,
      "plan": plan.plan,
      "estado": plan.estado,
      "descripcion": plan.descripcion,
      "imagenPlan": plan.imagenPlan,
      "valorComision": plan.valorComision
    },{headers});
  }
}
