import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Factibilidad } from 'src/app/models/oportunidad/factibilidad.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import swal from 'src/assets/styles/vendors/sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss'

import { AppConstants } from '../../app.constansts';
import { LoginService } from '../auth/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class FactibilidadService {

  _baseUrl: string;

  factibilidad: Factibilidad;
  constructor(private http: HttpClient, private loginService : LoginService) {
    this._baseUrl = AppConstants.baseURL;
   }

  crearFactibilidad(f, id) {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/oportunidad/PostFactComercial', {
      "idOportunidad": id,
      "Aprueba": f.aprueba,
      "Comentario": f.comentario
    },{headers})
  }
  crearFactibilidadTecnica(form,fb, estadoFact, idOportunidad, idComuna) {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/oportunidad/PostFactTecnica', {
      "factTecnica":
      {
        "idOportunidad": idOportunidad,
        "aprueba": estadoFact,
        "comentario": fb.comentario,
        "idMotivoRechazo": form.idMotivoRechazo,
        "idMotivoForzado": form.idMotivoForzado,
      },
      "direccion": {
        "calle": fb.calle,
        "numero": fb.numero,
        "idComuna": idComuna,
        "restoDireccion": fb.restoDireccion,
        "identificadorDireccion": fb.identificadorDireccion,
        "block": fb.block,
        "departamento": fb.departamento
      }
    },{headers})
  }

  crearFactibilidadTecnicaPorEjecutivo(form,fb, estadoFact, idOportunidad, idComuna) {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/oportunidad/PostFactTecnicaPorEjecutivo', {
      "factTecnica":
      {
        "idOportunidad": idOportunidad,
        "aprueba": estadoFact,
        "comentario": fb.comentario,
        "idMotivoRechazo": form.idMotivoRechazo,
        "idMotivoForzado": form.idMotivoForzado,
      },
      "direccion": {
        "calle": fb.calle,
        "numero": fb.numero,
        "idComuna": idComuna,
        "restoDireccion": fb.restoDireccion,
        "identificadorDireccion": fb.identificadorDireccion,
        "block": fb.block,
        "departamento": fb.departamento
      }
    },{headers})
  }

  crearFactibilidadTecnicaSinEjecutivo(idOportunidad, estadoFact, comentario)
  {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/oportunidad/PostFactTecnicaPendiente', {
      "factTecnica":
      {
        "idOportunidad": idOportunidad,
        "aprueba": estadoFact,
        "comentario": comentario
      }
    },{headers})
  }

  //#region Motivos

  getMotivoRechazoFactTec() {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl + '/api/motivos/getRechazoFactTecnica',{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }
  getMotivoForzarFactTec() {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl + '/api/motivos/getForzadoFactTecnica',{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }
  getMotivoForzarRechazoFactTec() {
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl + '/api/motivos/getRechazoForzadoFactTecnica',{headers}).pipe(map((resp: any) => {
      return resp;
    })
    );
  }
  //#endregion

  guardarFactibilidadTecForzada(form, estadoFact, idOportunidad){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/oportunidad/PostCierreForzar', {
      "factTecnica":
      {
        "idOportunidad": idOportunidad,
        "estadoForzado": estadoFact,
        "IdMotivoRechazoForzado": form.idMotivoRechazoForzado,
        "comentariosForzado": form.comentariosForzado,
      }
    },{headers})
  }
}
