import { Injectable } from '@angular/core';
import { Usuario } from 'src/app/models/negocio/usuario.model';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from '../auth/login/login.service';
import { AppConstants } from 'src/app/app.constansts';

@Injectable({
  providedIn: 'root'
})
export class AsignarService {
  
  _baseUrl: string;

  constructor(private http : HttpClient, private loginService : LoginService) { 
    //this.cargarStorage();
    this._baseUrl = AppConstants.baseURL;
  }

  //lista de oportunidadque se utiliza solo para el rol de ejecutivo de venta
  getPreAsignaOportunidad(idUsuario, mandantes){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl +'/api/Asignar/getPreAsignaOportunidad/'+ idUsuario +'/'+ mandantes,{headers});
  }

  //obtener todos los ejecutivos
  getAllEjecutivos(idOportunidad){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.get(this._baseUrl +'/api/Asignar/getEjecutivosReAsignacion/'+ idOportunidad,{headers});
  }

  AsignarEjecutivo(idOportunidad, idEjecutivo){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/Asignar/postAsignarEjecutivo/' + idOportunidad + '/' + idEjecutivo,"",{headers});
  }

  DevolverPool(idOportunidad){
    const  headers = new  HttpHeaders().set("Authorization", "Bearer " + this.loginService.token);
    return this.http.post(this._baseUrl + '/api/Asignar/postDevlopverPool/' + idOportunidad,{headers});
  }
}
